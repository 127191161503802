const logotext = "JP Tibério";
const meta = {
    title: "João Pedro Tibério",
    description: "I’m João Pedro data scientist _ Full stack devloper, currently working in São Paulo - Brazil",
};

const introdata = {
    title: "I’m João Pedro Tibério",
    animated: {
        first: "I love WordPress",
        second: "I code cool websites",
        third: "I love devops",
    },
    description: "My name is João Pedro Tibério, a Full Stack developer with a degree in Computer Science. I am always looking for new challenges, whether in small or large companies. I have expertise in customer service and digital solutions. Currently, I am helping lead technology teams at Studio Visual agency. Working and organizing groups for a specific project is one of my greatest interests and what I love to do, besides, of course, programming!",
    your_img_url: "",
};

const dataabout = {
    title: "A bit about my self",
    aboutme: "My name is João Pedro Tibério, a Full Stack developer with a degree in Computer Science. I am always looking for new challenges, whether in small or large companies. I have expertise in customer service and digital solutions. Currently, I am helping lead technology teams at Studio Visual agency. Working and organizing groups for a specific project is one of my greatest interests and what I love to do, besides, of course, programming!",
};
const worktimeline = [{
        jobtitle: "Designer of week",
        where: "YAdfi",
        date: "2020",
    },
    {
        jobtitle: "Designer of week",
        where: "Jamalya",
        date: "2019",
    },
    {
        jobtitle: "Designer of week",
        where: "ALquds",
        date: "2019",
    },
];

const skills = [{
        name: "Devops",
        value: 100,
    },
    {
        name: "WordPress",
        value: 100,
    },
    {
        name: "Javascript",
        value: 90,
    },
    {
        name: "PHP",
        value: 90,
    },
    {
        name: "Tailwind",
        value: 80,
    },
];

const services = [{
        title: "Wordpress Developer",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nisl euismod urna bibendum sollicitudin.",
    },
    {
        title: "Wordpress Developer",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nisl euismod urna bibendum sollicitudin.",
    },
    {
        title: "Wordpress Developer",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nisl euismod urna bibendum sollicitudin.",
    },
];

const dataportfolio = [{
        img: "https://picsum.photos/400/?grayscale",
        description: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/800/?grayscale",
        description: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/?grayscale",
        description: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/600/?grayscale",
        description: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/300/?grayscale",
        description: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/700/?grayscale",
        description: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },

    {
        img: "https://picsum.photos/400/600/?grayscale",
        description: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/300/?grayscale",
        description: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/?grayscale",
        description: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/550/?grayscale",
        description: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/?grayscale",
        description: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/700/?grayscale",
        description: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
];

const contactConfig = {
    YOUR_EMAIL: "jptiberio@icloud.com",
    YOUR_FONE: "+55 (11) 91578-8533",
    description: "WordPress Specialist. I offer expert consulting in Web Solutions, Website Development, Systems, and Infrastructure. Enhance your online presence with professional, tailored web services that drive results.",
    // creat an emailjs.com account 
    // check out this tutorial https://www.emailjs.com/docs/examples/reactjs/
    YOUR_SERVICE_ID: "service_id",
    YOUR_TEMPLATE_ID: "template_id",
    YOUR_USER_ID: "user_id",
};

const socialprofils = {
    github: "https://github.com/jptiberio",
    facebook: "https://www.facebook.com/jp.tib",
    linkedin: "https://www.linkedin.com/in/jo%C3%A3o-pedro-tib%C3%A9rio-11901965/",
    twitter: "https://x.com/jptiberio",
    whatsapp: "https://api.whatsapp.com/send?phone=+5511915788533",
    instagram: "https://www.instagram.com/jp.tiberio/",
};
export {
    meta,
    dataabout,
    dataportfolio,
    worktimeline,
    skills,
    services,
    introdata,
    contactConfig,
    socialprofils,
    logotext,
};